
export default {
  props: {
    numberOfPositions: {
      type: Number,
      default: 5,
    },
    voiceReaderDescription: {
      type: String,
      default: ``,
    },
    moduleTitleStyle: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ``,
    },
    subTitle: {
      type: String,
      default: ``,
    },
    statType: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statLabel () {
      return this.statType?.stat_label;
    },
    statTypeObject () {
      return this.statType?.stat_type;
    },
    statTypeEntitiesWithPositionsAppended () {
      return this.statTypeObject?.entities?.reduce(
        (paddedEntities, player, i) => {
          const score = player.value;
          const prevScore = paddedEntities[i].value;

          const staggeredIndex =
          paddedEntities[i].position ? paddedEntities[i].position : i;

          player.position = score === prevScore ? paddedEntities[i].position : staggeredIndex + 1;

          return paddedEntities;
        },
        [{ value: null }, ...this.statTypeObject.entities, { value: null }],
      )
        .filter(entity => entity.value)
      ;
    },
  },
};
