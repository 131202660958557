
export default {
  props: {
    title: {
      default: ``,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        title: {
          data: ``,
          show: false,
        },
        polar: {
          radius: [4, `60%`],
        },
        radiusAxis: {
          max: 20,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: [`#fff`, `#D6D6D6`],
            },
          },
        },
        angleAxis: {
          type: `category`,
          data: [],
          startAngle: 90,
          axisLabel: {
            color: `#000`,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        tooltip: {},
        series: {
          type: `bar`,
          data: [],
          coordinateSystem: `polar`,
          label: {
            show: true,
          },
          barCategoryGap: 0,
        },
      },
    };
  },
  computed: {
    allStatNames() {
      return this.data?.map((stat) => stat.name) || null;
    },
    allStatValues() {
      return this.data?.map((stat) => stat.value) || null;
    },
    seriesValues() {
      return (
        this.allStatValues?.map((value, index) => ({
          value,
          itemStyle: {
            color: this.chartColours[index],
          },
          label: {
            show: true,
            color: `#000`,
            textBorderColor: this.chartColours[index],
            fontFamily: `bebas-neue-pro, Arial`,
            fontWeight: `bold`,
            fontSize: 14,
          },
        })) || null
      );
    },
    radiusAxisMax() {
      return this.allStatValues ? Math.max(...this.allStatValues) + 1 : null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.options.angleAxis.data = this.allStatNames;
      this.options.series.data = this.seriesValues;
      this.options.radiusAxis.max = this.radiusAxisMax;
      this.options.title.data = this.title;
    });
  },
};
