
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    side: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconClasses () {
      const icon = this.type.toLowerCase() === `goal` ? `goal` : `icon-cross`;
      const colourType = this.type.toLowerCase() === `goal` ? `bg` : `c`;
      const colour = this.side === `home` ? `${colourType}-primary` : `${colourType}-black`;
      return `${icon} ${colour}`;
    },
  },
};
