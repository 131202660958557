
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    currentTab: {
      type: String,
      required: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    collapseToSelect: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selectedTab: null,
    };
  },
  computed: {
    firstTab () {
      return this.tabs[0];
    },
    lastTab () {
      return this.tabs[this.tabs.length - 1];
    },
  },
  watch: {
    currentTab (tab) {
      this.selectedTab = tab;
    },
  },
  mounted () {
    this.selectedTab = this.currentTab;
  },
  methods: {
    setTab (tab) {
      this.$emit(`tabChange`, tab);
    },
    setSelectTab (event) {
      this.setTab(event.target.value);
    },
    handleKeys (event) {
      if (event.key === `ArrowRight`) {
        if (this.currentTab === this.lastTab) {
          this.currentTab = this.firstTab;
        } else {
          const currentIndex = this.tabs.indexOf(this.currentTab);
          this.currentTab = this.tabs[currentIndex + 1];
        }
      } else if (event.key === `ArrowLeft`) {
        if (this.currentTab === this.firstTab) {
          this.currentTab = this.lastTab;
        } else {
          const currentIndex = this.tabs.indexOf(this.currentTab);
          this.currentTab = this.tabs[currentIndex - 1];
        }
      }
    },
  },
};
