export default {
  data () {
    return {
      taxonomySchema: {
        players: {
          name: `players`,
          slug: `player_stat`,
          prop: `playerStatTypes`,
        },
        team: {
          name: `teams`,
          slug: `team_stat`,
          prop: `teamStatTypes`,
        },
      },
      taxonomyStatTypes: {
        playerStatTypes: {
          "General": [], // NOTE: we currently have to statically state tabs because otherwise the data won't load until you switch tabs; this is because the `data` props are rendered before all else
          "Attack": [],
          "Defence": [],
          "Discipline": [],
          "Set Piece": [],
        },
        teamStatTypes: {
          "League Table": [],
          "Attack": [],
          "Defence": [],
          "Discipline": [],
          "Set Piece": [],
          "Distribution and Possession": [],
        },
      },
      allStatTypesInitialDataWithinCurrentTaxonomy: {}, // NOTE: initial data is the data from CMS config, from which we then need to create Promises and return the corresponding request data from the API
      alternativeCharts: {
        goalPositions: {},
        shotPlacements: {},
        leaguePositions: {},
        leagueTable: {},
      },
      statsTabs: {
        defaultTabs: [],
        currentTab: null,
      },
      statsFilters: {
        seasons: {
          selectedSeason: ``,
        },
        taxonomy: {
          selectedTaxonomy: `team`,
        },
      },
    };
  },
  computed: {
    playerStatTypes () {
      const playerStatTypesPath = this.currentTaxonomyStatTypesPath;
      return playerStatTypesPath?.length ? playerStatTypesPath : [];
    },
    teamStatTypes () {
      const teamStatTypesPath = this.currentTaxonomyStatTypesPath;
      return teamStatTypesPath?.length ? teamStatTypesPath : [];
    },
  },
  methods: {
    // INTERFACES:
    async createChartDataForAllStatTypes () {
      this.isLoading = true; // REVISIT: side-effect
      const allStatTypesPromises = await this.createPromisesForAllStatTypes({
        allStatTypes: this.allStatTypesInitialDataWithinCurrentTaxonomy,
      });
      if (!allStatTypesPromises) return null;
      const allFulfilledStatTypesPromises =
        await this.fulfilPromisesForAllStatTypesInGroups({
          allStatTypesPromises,
        });
      this.isLoading = false;
      return allFulfilledStatTypesPromises;
    },
    createPromisesForAllStatTypes ({ allStatTypes }) {
      const allStatTypesDeepCopy = this.objectDeepCopy(allStatTypes);
      const allStatTypesPromises = [];

      for (const statTypes in allStatTypesDeepCopy) {
        const statTypesPromises = {
          name: allStatTypesDeepCopy[statTypes].name,
          displayType: allStatTypesDeepCopy[statTypes].displayType,
          promises: [],
        };
        for (const statType of allStatTypesDeepCopy[statTypes].data) {
          const statTypeData =
            this.getStatTypeDataAlongWithDesiredLabel(statType);
          statTypesPromises.promises.push(statTypeData);
        }
        allStatTypesPromises.push(statTypesPromises);
      }
      return allStatTypesPromises;
    },
    async fulfilPromisesForAllStatTypesInGroups ({ allStatTypesPromises }) {
      const allStatTypesResults = [];
      for (const statGroupTypePromises of allStatTypesPromises) {
        // NOTE: statGroupTypePromises refers to all the nested promises we created for each statType (e.g., 'Shots On/Off Target/Blocked' -- that's 3 promises we need to parse as a group)
        const statGroupTypeResults = {
          name: statGroupTypePromises.name,
          displayType: statGroupTypePromises.displayType,
          results: [],
        };
        await Promise.allSettled(statGroupTypePromises.promises).then(
          statGroupTypesResults => {
            statGroupTypesResults.forEach(statTypeResult => {
              try {
                statGroupTypeResults.results.push({
                  ...statTypeResult.value,
                });
              } catch {}
            });
          },
        );
        allStatTypesResults.push(statGroupTypeResults);
      }
      return allStatTypesResults;
    },
    async parseStatTypeObjectAndReturnTheData ({
      taxonomyName,
      statTypeObject,
    }) {
      const statTypeObjects = (_ => {
        const statTypeObjects = [];
        statTypeObjects.push(this.getStatTypeObject(statTypeObject));
        return statTypeObjects;
      })();

      const statTypeNames = this.getStatTypeNames(statTypeObjects); // NOTE: statTypeNames refers to the 'displayName' key; not to be confused with the ACF name
      const joinedStatTypeNames = statTypeNames.join(`||`);

      const statTypeData = await this.getTopStats(
        taxonomyName,
        joinedStatTypeNames,
      ).catch(_ => null);

      return Object.values(statTypeData)[0];
    },
    async getStatTypeDataAlongWithDesiredLabel (statType) {
      const statLabel = { ...statType }.stat_label;
      const statTypeObject = { ...statType }.stat_type;

      const statTypeData = await this.parseStatTypeObjectAndReturnTheData({
        taxonomyName: this.taxonomySchema[this.selectedTaxonomy].name,
        statTypeObject,
      });
      return { stat_label: statLabel, stat_type: { ...statTypeData } };
    },
    getCustomChartDataIfItDoesntAlreadyExist ({
      statTypeName,
      statTypePath,
      chartFn,
    }) {
      this.checkIfStatTypeExistsInAllStatTypes({
        statTypeName,
        allStatTypes: this.allStatTypesInitialDataWithinCurrentTaxonomy,
      }) &&
        (Object.values(this.alternativeCharts[statTypePath]).length ||
          (this.alternativeCharts[statTypePath] = chartFn));
    },
    resetChartData () {
      const resetChartData = Object.keys(
        this.taxonomyStatTypes[this.currentTaxonomyProp],
      ).reduce((accumulator, key) => ({ ...accumulator, [key]: [] }), {});
      this.taxonomyStatTypes[this.currentTaxonomyProp] = { ...resetChartData };
    },
    resetCustomChartData () {
      const resetCustomChartData = Object.keys(this.alternativeCharts).reduce(
        (accumulator, key) => ({ ...accumulator, [key]: {} }),
        {},
      );
      this.alternativeCharts = { ...resetCustomChartData };
    },
    async recalibrateCustomChartData () {
      this.getCustomChartDataIfItDoesntAlreadyExist({
        statTypeName: `origin_of_goal`,
        statTypePath: `goalPositions`,
        chartFn: await this.getGoalPositionsData({
          selectedSeason: this.selectedSeason,
          selectedTeamId: this.selectedTeamFromCms?.id,
        }),
      });
      this.getCustomChartDataIfItDoesntAlreadyExist({
        statTypeName: `goal`,
        statTypePath: `shotPlacements`,
        chartFn: await this.getShotPlacementsData({
          selectedSeason: this.selectedSeason,
          selectedTeamId: this.selectedTeamFromCms?.id,
        }),
      });
      this.getCustomChartDataIfItDoesntAlreadyExist({
        statTypeName: `league_table`,
        statTypePath: `leagueTable`,
        chartFn: this.getLeagueTableData({
          selectedSeason: this.selectedSeason,
          selectedTeamId: this.selectedTeamFromCms?.id,
        }),
      });
      this.getCustomChartDataIfItDoesntAlreadyExist({
        statTypeName: `league_positions`,
        statTypePath: `leaguePositions`,
        chartFn: await this.getLeaguePositionsData({
          selectedSeason: this.selectedSeason,
          selectedTeamId: this.selectedTeamFromCms?.id,
        }),
      });
    },
    // ABSTRACTION BARRIER:
    async getTopStats (taxonomy, statTypeNames) {
      const statTypeNamesCopy = statTypeNames.slice();
      const teamId = this.selectedTeamFromCms?.id;
      const params = { stat_types: statTypeNamesCopy };
      const paramsWithTeamId = { ...params, desired_team_id: teamId };

      const stats = await this.$getCustomStats(
        `season/${this.selectedSeason}/topstats/${taxonomy}${
          taxonomy === `players` && teamId ? `/${teamId}` : ``
        }`,
        taxonomy === `teams` ? paramsWithTeamId : params,
      );
      return stats ?? null;
    },
    getAllStatTypesFromBlock ({ taxonomy, block }) {
      if (!block) return null;
      const statTypes = [];
      const blockDeepCopy = this.objectDeepCopy(block);
      [...blockDeepCopy[taxonomy]].forEach(statGroup => {
        const statGroupTypes = {
          name: statGroup.name_override,
          displayType: statGroup.display_type,
          data: [],
        };
        statGroup[taxonomy].forEach(stat => {
          statGroupTypes.data.push({
            stat_label: stat.stat_label,
            stat_type: stat.stat_type,
          });
        });
        statTypes.push(statGroupTypes);
      });
      return statTypes;
    },
    getStatTypeObject (statType) {
      const statTypeDeepCopy = this.objectDeepCopy(statType);
      const filteredStatTypeDeepCopy = {
        ...Object.entries(statTypeDeepCopy)
          .filter(statType => statType[1].length)
          .flat(2)[1],
      };
      return filteredStatTypeDeepCopy;
    },
    getStatTypeNames (statTypeObjects) {
      const statTypeNames = [];
      for (const statTypeObject of statTypeObjects) {
        statTypeNames.push({ ...statTypeObject }.displayName);
      }
      return statTypeNames;
    },
    getStatTypePositions (requestUrl, requestParams) {
      return this.asyncResponseHandler(
        async () => await this.$getCustomStats(requestUrl, requestParams),
      );
    },
    getChartData (statTypes) {
      if (!statTypes.some(statType => statType.stat_type)) return [];
      const chartData = [];
      for (const statType of statTypes) {
        chartData.push({
          name: statType?.stat_label,
          value: statType?.stat_type?.desired_team?.value,
          league_average: statType?.stat_type?.league_average,
        });
      }
      return chartData;
    },
    checkIfStatTypeExistsInAllStatTypes ({ statTypeName, allStatTypes }) {
      return (
        allStatTypes.find(
          statType => statType.displayType === statTypeName,
        ) || null
      );
    },
    async getGoalPositionsData ({ selectedSeason, selectedTeamId }) {
      const goalPositions = await this.getStatTypePositions(
        `season/${selectedSeason}/goalpositions/team/${selectedTeamId}`,
        {},
      ).catch(_ => null);
      if (!goalPositions) return null;
      const goalPositionsDataRestructured = {
        goalPositions: {
          Attack: [...goalPositions.Forward].concat(
            goalPositions.Winger.concat(goalPositions.Striker),
          ),
          Midfield: [
            ...goalPositions.Midfielder.concat(
              goalPositions[`Defensive Midfielder`],
            ),
          ],
          Defence: [
            ...goalPositions[`Central Defender`].concat(
              goalPositions.Goalkeeper,
            ),
          ],
        },
      };
      return goalPositionsDataRestructured || null;
    },
    async getShotPlacementsData ({ selectedSeason, selectedTeamId }) {
      const shotPlacements = await this.$getCustomStats(
        `season/${selectedSeason}/shotplacement/team/${selectedTeamId}`,
      ).catch(_ => null);
      if (!shotPlacements) return null;
      delete shotPlacements[`Attempt Saved`];
      delete shotPlacements.Miss;
      return shotPlacements;
    },
    async getLeaguePositionsData ({ selectedSeason, selectedTeamId }) {
      const leaguePositions = await this.getStatTypePositions(
        `ladder/${selectedSeason}/${selectedTeamId}`,
        {},
      );
      if (!leaguePositions) return;
      const sortedLeaguePositions = [
        ...leaguePositions.sort((a, b) => a.matchday - b.matchday),
      ];

      const leaguePositionsData = {
        positions: sortedLeaguePositions.map(
          position => position.teams.team.position,
        ),
        matchdays: sortedLeaguePositions.map(position => ({
          matchday: position.matchday,
          teams: position.teams,
        })),
      };

      return { ...leaguePositionsData };
    },
    getLeagueTableData () {
      return {
        teamId: this.selectedTeamFromCms?.id,
        id: this.selectedSeason,
      };
    },
    setTab (tab) {
      this.statsTabs.currentTab = tab;
      // NOTE: Usual hash setting will cause the page to scroll to an element;
      // the following sets the hash in the URL WITHOUT reloading the route
      window.history.replaceState(null, null, `#${this.slugify(tab)}`);
    },
  },
};
