import { render, staticRenderFns } from "./ShotPlacementChart.vue?vue&type=template&id=2fdf9758&scoped=true&"
import script from "./ShotPlacementChart.vue?vue&type=script&lang=js&"
export * from "./ShotPlacementChart.vue?vue&type=script&lang=js&"
import style0 from "./ShotPlacementChart.vue?vue&type=style&index=0&id=2fdf9758&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fdf9758",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GoalPostSvg: require('/src/components/SVG/GoalPostSvg.vue').default,ShotPlacementIcon: require('/src/components/Stats/Charts/Custom/ShotPlacementIcon.vue').default})
