
export default {
  props: {
    title: {
      type: String,
      default: ``,
    },
    data: {
      type: Array,
      default: () => [],
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    startColourIndex: {
      type: Number,
      default: 0,
    },
    hideValues: {
      type: Boolean,
      default: false,
    },
    showNumber: {
      type: Boolean,
      default: false,
    },
    leagueAverage: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      options: {
        legend: {
          show: false,
        },
        tooltip: {
          trigger: `item`,
        },
        series: [
          {
            name: `Possession`,
            type: `pie`,
            clockwise: false, // Home team on left
            radius: [`65%`, `90%`],
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: `#fff`,
              borderWidth: 4,
            },
            label: {
              show: false,
            },
            tooltip: {
              formatter: `{b}: {c}`,
              backgroundColor: `#B5050F`,
              borderColor: `#B5050F`,
              textStyle: {
                color: `#fff`,
                fontSize: 18,
              },
              padding: 8,
              extraCssTest: `border-radius: 8px; font-family: bebas-neue-pro;`,
            },
            labelLine: {
              show: false,
            },
            // Occasionally we may need to add the data after mounting e.g. if using a computed value
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    dataWithColours () {
      // Add chart colours to each piece of data
      return this.data.map((item, index) => ({
        ...item,
        itemStyle: {
          color: this.chartColours[index + this.startColourIndex],
        },
      }));
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.setData();
    });
  },
  methods: {
    setData () {
      this.options.series[0].data = this.dataWithColours;
      if (this.percentage) {
        this.options.series[0].tooltip.formatter += `%`;
      }
    },
  },
};
