
export default {
  props: {
    title: {
      type: String,
      default: ``,
    },
    shots: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      leftPostX: 55.8, // x co-ord of the left post
      rightPostX: 44.2, // x co-ord of the right post
      crossBarY: 40, // y co-ord of the crossbar
      maxWidth: 105, // The % right limit of the shot displayed
      minWidth: -10, // The % left limit of the shot displayed
      maxHeight: 110, // At % what point to limit the height of the shot displayed
    };
  },
  methods: {
    iconPosition (shot) {
      // Re-label the co-ordinates from the perspective of the goal front-on
      const x = shot[`Goal mouth y co-ordinate`];
      const y = shot[`Goal mouth z co-ordinate`];

      /*
        Pixel version if needed:
        const originRight: 120, // px position of the centre of the goal
        const xToPost: 5.8, // Degrees between origin and outside of post
        const rightVal = (originRight / xToPost) * x - 914.483;
        const bottomVal = 2 * y;
      */

      /*
        Percentage version
      */

      // Calculate distance from right post as a percentage
      const rightVal = (x - this.rightPostX) / (this.leftPostX - this.rightPostX) * 100;
      // Same with bottom, from ground
      const bottomVal = y / this.crossBarY * 100;

      // Check boundaries
      const right = rightVal < this.minWidth
        ? `-1rem`
        : rightVal > this.maxWidth
          ? `calc(100% + 1rem)`
          : `${rightVal}%`;
      const bottom = bottomVal > this.maxHeight ? `calc(100% + .5rem)` : `${bottomVal}%`;

      return {
        right: `${right}`,
        bottom: `${bottom}`,
      };
    },
  },
};
